var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "updateAndroid" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "text" },
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [_vm._v("<<返回")]
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("TitleModule", { attrs: { title: _vm.title } }),
          _c(
            "div",
            { staticClass: "searchWrapper" },
            [
              _c(
                "div",
                { staticClass: "disabledAddListWrapper" },
                [
                  !_vm.formInline.channelList.downloadUrl
                    ? _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload",
                          attrs: {
                            disabled: !_vm.isEdit,
                            action: "/acb/2.0/application/patch/upload",
                            headers: _vm.uploadHeader,
                            accept: "jar",
                            name: "file",
                            data: {
                              applicationId: _vm.$route.query.applicationId,
                            },
                            enctype: "multipart/form-data",
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess,
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-error": _vm.handleAvatarError,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "small",
                                type: "text",
                                disabled: !_vm.isEdit,
                              },
                            },
                            [
                              _vm._v(" 上传补丁包 "),
                              _c("br"),
                              _vm._v("(支持jar文件，最大不超过50MB) "),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.formInline.channelList.downloadUrl,
                          expression: "formInline.channelList.downloadUrl",
                        },
                      ],
                      staticClass: "upload uploadText",
                    },
                    [
                      _c("label", [_vm._v("包名：")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.formInline.channelList.packageName)),
                      ]),
                      _c("label", [_vm._v("大小：")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.formInline.channelList.fileSize)),
                      ]),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isEdit === "add",
                            expression: "isEdit === 'add'",
                          },
                        ],
                        staticClass: "el-icon-error",
                        on: { click: _vm.removePatch },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { width: "600px" },
                  attrs: {
                    "label-position": "right",
                    disabled: !_vm.isEdit,
                    "label-width": "120px",
                    model: _vm.formInline,
                    rules: _vm.rules,
                  },
                },
                [
                  _c("h2", [_vm._v("高级配置")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "应用版本号", prop: "versionName" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formInline.versionName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "versionName", $$v)
                            },
                            expression: "formInline.versionName",
                          },
                        },
                        _vm._l(_vm.versionNameList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "升级时间", prop: "upgradeType" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            disabled:
                              _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                            label: 1,
                          },
                          model: {
                            value: _vm.formInline.upgradeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "upgradeType", $$v)
                            },
                            expression: "formInline.upgradeType",
                          },
                        },
                        [_vm._v("立即升级")]
                      ),
                      _c(
                        "el-radio",
                        {
                          staticStyle: { "margin-right": "20px" },
                          attrs: {
                            disabled:
                              _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                            label: 2,
                          },
                          model: {
                            value: _vm.formInline.upgradeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "upgradeType", $$v)
                            },
                            expression: "formInline.upgradeType",
                          },
                        },
                        [_vm._v("定时升级")]
                      ),
                      _c("el-date-picker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.formInline.upgradeType !== 1,
                            expression: "formInline.upgradeType !== 1",
                          },
                        ],
                        attrs: {
                          disabled:
                            _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                          clearable: false,
                          editable: false,
                          "picker-options": _vm.pickerOptions,
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择日期时间",
                        },
                        on: { change: _vm.upgradeTimeSelect },
                        model: {
                          value: _vm.formInline.upgradeTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "upgradeTime", $$v)
                          },
                          expression: "formInline.upgradeTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.remarks"),
                        prop: "updateDesc",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入更新说明",
                          maxlength: "200",
                          autosize: { minRows: 4, maxRows: 8 },
                        },
                        model: {
                          value: _vm.formInline.updateDesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "updateDesc", $$v)
                          },
                          expression: "formInline.updateDesc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "20px" },
                },
                [
                  _vm.isEdit
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { width: "88px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.submitData },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.preservation")))]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "88px" },
                      attrs: { type: "info" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.isEdit ? "取消" : "返回"))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }